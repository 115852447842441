import { API } from 'aws-amplify';

const axios = require('axios');

export const getJobs = async () => {
  return axios({
    method: 'get',
    url: 'https://boards-api.greenhouse.io/v1/boards/nanlabs/jobs?content=true',
  });
};

export const getJobById = async () => {
  return axios({
    method: 'get',
    url: 'https://boards-api.greenhouse.io/v1/boards/nanlabs/jobs?content=true',
  });
};

export const getJobsWithFields = async () => {
  const jobs = await getJobs();
  jobs.map(async job => {
    const jobWithFields = await getJobById(job.id);
    return jobWithFields;
  });
  return jobs;
};

export const postJobApplication = async (id, jobData) => {
  const encodedAPIKey = Buffer.from(process.env.GATSBY_GREENHOUSE_API_KEY).toString('base64');
  return axios({
    method: 'post',
    url: `https://boards-api.greenhouse.io/v1/boards/nanlabs/jobs/${id}`,
    data: jobData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${encodedAPIKey}`,
    },
  });
};

export const postJobForm = async (formData, jobId) => {
  return axios({
    method: 'post',
    url: `https://2i4w6bgp5j.execute-api.us-west-2.amazonaws.com/staging/greenhouse`,
    data: { formData, jobId },
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // API.post('newsletterApi', '/greenhouse', {
  //   body: { formData, jobId },
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  // });
  // .then(res => {
  //   console.log('greenhouse res: ', res);
  // })
  // .catch(error => {
  //   if (error.response) {
  //     console.log('Greenhouse error: ', error);
  //   } else {
  //     console.log('Greenhouse error');
  //   }
  // });
};
