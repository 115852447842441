import React from 'react';
import PropTypes from 'prop-types';

import { Container, Label } from './styles';
import DragAndDropzone from 'atomic-design/molecules/dropzone';

const FormDropzone = ({ label, name, field, handleFileUpload }) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <DragAndDropzone
        handleDrop={acceptedFiles => handleFileUpload(acceptedFiles[0], field.name)}
      />
    </Container>
  );
};

FormDropzone.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  handleFileUpload: PropTypes.func.isRequired,
};

export default FormDropzone;
