import styled from 'styled-components';
import Button from 'atomic-design/atoms/button';
// import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';

export const StyledDropzoneWrapper = styled.div`
  display: flex;
  border: 2px dashed rgba(34, 51, 84, 0.4);
  border-radius: 0.5rem;
  padding: 3rem 2rem;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

// const UploadIcon = styled(CloudUploadTwoToneIcon)`
//   color: #3987ff;
//   font-size: 4.5rem;
// `;

export const StyledDropzoneContainer = styled.div`
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  margin: 1rem;
  padding: 0.4rem 0.75rem;
  padding-top: 0.5rem;
  font-weight: 500;
  box-shadow: 0px 1px 4px rgb(25 117 255 / 25%), 0px 3px 12px 2px rgb(25 117 255 / 35%);
`;

export const StyledSideText = styled.p`
  margin: 0;
  text-align: center;
  color: #6e759f;
`;

export const FileAcceptedText = styled(StyledSideText)`
  font-size: ${({ theme }) => theme.newTheme.font.text4.size};
`;

export const FileRejectedText = styled(FileAcceptedText)`
  color: ${({ theme }) => theme.newTheme.color.error};
`;
