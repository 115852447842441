import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import NavigationCards from 'atomic-design/molecules/navigation-cards';

import { getAbsoluteUrl } from 'common/helpers';
import { convertToSlug } from 'common/string-helpers';
import PageSection from 'atomic-design/atoms/page-section';
import CategoryOpenGraph from 'static/images/metatags/category-open-graph.jpg';
import {
  Title,
  Location,
  HeaderText,
  StyledForm,
  TextGradient,
  LocationContainer,
} from 'styles/templates/job-styles.js';
import Button from 'atomic-design/atoms/button';
import theme from '../styles/theme';
import { postJobForm } from '../services/greenhouse-service';
import { NewStyledWrapper } from '../components/atomic-design/organisms/rich-text-wrapper/styles';
import FormInput from '../components/atomic-design/organisms/contact-form/form-input';
import FormDropzone from '../components/atomic-design/organisms/contact-form/form-dropzone';

const Job = props => {
  const { location } = props;
  const { currentJobPost, previous, next, content, formFields, id } = props.pageContext;
  const [formData, setFormData] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [subMessage, setSubMessage] = useState('');
  
  const title = `Job Post | ${currentJobPost.title} - NaNLABS`;
  const description = `We are on the lookout for a ${currentJobPost.title} to join us!`;

  useEffect(() => {
    formFields.forEach(field => {
      field.fields.forEach(f => {
        if (f.type !== 'input_file') setFormData(oldData => ({ ...oldData, [f.name]: '' }));
      });
    });
  }, [formFields]);

  const htmlDecode = input => {
    if (typeof document === 'undefined') return '';
    const e = document.createElement('div');
    Object.assign(e, { innerHTML: input });
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  };

  const handleSubmit = async e => {
    setSubMessage('Sending application...');
    e.preventDefault();
    const jobId = id.replace('Greenhouse__Job__', '');
    const res = await postJobForm(formData, jobId);
    if (res.status === 200) {
      setSuccessMessage('Application submitted!');
    } else {
      setSubMessage('Something went wrong, please try again');
    }
  };

  const getBase64FromFile = (file, fieldName) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFormData(oldData => ({
        ...oldData,
        [fieldName + '_content']: reader.result.slice(28),
        [fieldName + '_content_filename']: file.name,
      }));
    };
    reader.onerror = error => {
      console.log('Error: ', error);
    };
  };

  const handleFileUpload = (file, fieldName) => {
    file && getBase64FromFile(file, fieldName);
  };

  return (
    <Layout bodyType="thin" path={location.pathname}>
      <MetaTags
        title={title}
        charSet="utf-8"
        image={getAbsoluteUrl(CategoryOpenGraph)}
        description={description}
        type="website"
        viewport="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <PageSection containerSize="s">
        <HeaderText>
          <LocationContainer>
            <Location>{currentJobPost.location}</Location>
          </LocationContainer>
          <Title>
            <span>We are Hiring:</span>
            {currentJobPost.title}
          </Title>
        </HeaderText>
      </PageSection>
      <PageSection
        containerSize="s"
        padding={`0 0 ${theme.newTheme.spacing(16)} 0`}
        mobilePadding={`0 0 ${theme.newTheme.spacing(7)} 0`}
      >
        <NewStyledWrapper dangerouslySetInnerHTML={{ __html: htmlDecode(content) }} />
      </PageSection>
      <PageSection padding={`0 0 ${theme.newTheme.spacing(16)} 0`}>
        <StyledForm id="application-form" onSubmit={handleSubmit}>
          {formFields?.map(field => (
            <>
              {field.fields
                .filter(f => f.type !== 'input_file')
                .map(f => (
                  <>
                    {f.type === 'input_text' && (
                      <FormInput
                        type="text"
                        id={f.name}
                        name={f.name}
                        onChange={e => {
                          const value = e.target.value;
                          setFormData(oldData => ({ ...oldData, [f.name]: value }));
                        }}
                        required={field.required}
                        placeholder=""
                        label={field.label}
                      />
                    )}
                  </>
                ))}
              {field.fields
                .filter(f => f.type === 'input_file' && f.name !== 'cover_letter')
                .map(f => (
                  <FormDropzone handleFileUpload={handleFileUpload} field={f} label={field.label} />
                ))}
            </>
          ))}
          {successMessage !== '' ? (
            <TextGradient>{successMessage}</TextGradient>
          ) : (
            <>
              <Button>Send</Button>
              <p>{subMessage}</p>
            </>
          )}
        </StyledForm>
      </PageSection>
      <PageSection containerSize="s">
        <NavigationCards
          previous={previous.node}
          previousTo={`/careers/${convertToSlug(previous ? previous.node.title : '')}`}
          next={next ? next.node : undefined}
          nextTo={`/careers/${convertToSlug(next ? next.node.title : '')}`}
          from="Careers"
          withArrow
        />
      </PageSection>
    </Layout>
  );
};

Job.propTypes = {
  currentJobPost: PropTypes.shape({
    title: PropTypes.string,
    excerpt: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  description: PropTypes.shape({
    json: PropTypes.instanceOf(Object),
  }).isRequired,
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.node,
    previous: PropTypes.node,
  }).isRequired,
  previous: PropTypes.shape({
    node: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
  next: PropTypes.shape({
    node: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
};

Job.defaultProps = {
  currentJobPost: null,
  previous: undefined,
  next: undefined,
};

export default Job;
