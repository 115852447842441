import React from 'react';
// import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import Dropzone from 'react-dropzone';
import {
  StyledDropzoneWrapper,
  StyledDropzoneContainer,
  ButtonWrapper,
  StyledButton,
  StyledSideText,
  FileAcceptedText,
  FileRejectedText,
} from './styles';

const DragAndDropzone = ({ handleDrop, dzName = '' }) => {
  const filesMessage = (acceptedFiles, fileRejections) => {
    if (acceptedFiles[0]) {
      return acceptedFiles.map(file => (
        <FileAcceptedText key={file.path}>
          {file.path} - {file.size} bytes
        </FileAcceptedText>
      ));
    }
    if (fileRejections[0]) {
      return fileRejections.map(({ file, errors }) =>
        errors.map(e => (
          <FileRejectedText key={file.path}>
            Only files with the following extensions are allowed: .pdf .doc .docx
          </FileRejectedText>
        ))
      );
    }
  };

  return (
    <Dropzone
      onDrop={handleDrop}
      noClick
      maxFiles={1}
      accept={{
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      }}
    >
      {({ getRootProps, getInputProps, acceptedFiles, fileRejections, open }) => (
        <StyledDropzoneWrapper>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <StyledDropzoneContainer>
              <ButtonWrapper>
                <StyledSideText>Drag a file or</StyledSideText>
                <StyledButton variant="contained" onClick={open} type="button">
                  Upload from computer
                </StyledButton>
                {filesMessage(acceptedFiles, fileRejections)}
              </ButtonWrapper>
            </StyledDropzoneContainer>
          </div>
        </StyledDropzoneWrapper>
      )}
    </Dropzone>
  );
};

export default DragAndDropzone;
