import styled from 'styled-components';

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.newTheme.spacing(26)} 0 0`};
  background: ${props => props.background};
  width: 100%;

  p {
    color: ${({ theme }) => theme.newTheme.color.white};
    font-family: ${({ theme }) => theme.newTheme.font.family};
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    padding: 2px 0;
    margin: 0;
  }
`;

export const LocationContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => `${theme.newTheme.spacing(4)} 0`};

  // @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
  //   padding: ${({ theme }) => `${theme.newTheme.spacing(3)} 0`};
  // }
`;

export const Location = styled.h4`
  color: ${({ theme }) => theme.newTheme.color.grey.light};
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  font-style: normal;
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  padding: 0;
  margin: 0;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  font-size: ${({ theme }) => theme.newTheme.font.title1.size};
  line-height: ${({ theme }) => theme.newTheme.font.title1.lineHeight};
  text-transform: capitalize;
  color: ${({ theme }) => theme.newTheme.color.grey.dark};

  & span {
    display: block;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.title2.size};
    line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
  }
`;

export const StyledForm = styled.form`
  max-width: 40rem;
  margin: auto;
`;

export const StyledDropzoneContainer = styled.div`
  margin-top: -1.5em;
  margin-bottom: 1rem;
`;

export const TextGradient = styled.p`
  background: ${({ theme }) => theme.newTheme.color.gradient.text};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
